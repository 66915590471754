<template>
  <CModal
    size="xl"
    :title="title"
    :show="show"
    :closeOnBackdrop="false"
    :centered="true"
  >
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <p v-if="info">{{ info }}</p>

    <form action="" @submit.prevent="onSubmit" ref="form">
      <CInput
        type="number"
        min="0"
        step="0.1"
        v-model="form.weight"
        :append="unit"
      >
        <template #label>
          <strong>Peso <em class="text-danger">*</em></strong>
        </template>
      </CInput>

      <CTextarea
        label="Messaggio"
        placeholder="inserisci informazioni aggiuntive sul prodotto/lotto..."
        v-model="form.msg"
        rows="9"
      />
    </form>
    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close"
        >ANNULLA</CButton
      >
      <CButton color="primary" @click.prevent="onSubmit">CONFERMA</CButton>
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import { getPriceUnitOptions } from "../../../../helpers/options";
import { getOptionLabel } from "../../../../helpers/common";
import EventBus from "../../../../helpers/EventBus";
export default {
  name: "OrdersWeightModal",

  components: {},

  data() {
    return {
      title: "Inserisci il peso",
      unit: "",
      form: {
        weight: 0,
        msg: "",
      },
    };
  },

  computed: {
    lang() {
      return this.$i18n.locale;
    },

    show() {
      return this.$store.state.productWeight.show;
    },

    entryData() {
      return this.$store.state.productWeight.entryData;
    },

    info() {
      const productName = get(
        this.entryData,
        `row.ppm.lot.productproducer.translations.${this.lang}.name`,
        ""
      );
      const variantName = get(
        this.entryData,
        `row.ppm.lot.translations.${this.lang}.name`,
        ""
      );
      return `${productName} - ${variantName}`;
    },
  },

  watch: {
    entryData: {
      handler: function (val) {
        this.form.weight = get(val, "row.qta", 0);
        this.unit = getOptionLabel(
          get(val, "row.ppm.lot.productproducer.pw_unit"),
          getPriceUnitOptions()
        );
      },
      deep: true,
    },
  },

  methods: {
    close() {
      this.$store.commit("productWeight/closeModal");
    },

    onSubmit() {
      const payload = {
        lod_id: get(this.entryData, "row.id"),
        weight: this.form.weight,
        msg: this.form.msg,
      };

      this.$store
        .dispatch("productWeight/editWeight", payload)
        .then((response) => {
          EventBus.$emit("orders:priceUpdate", {
            response: get(response, "data.payload"),
            weightEntryData: this.entryData,
          });
          this.close();
        })
        .catch((error) => {
          console.error(error);
          self.$store.dispatch("toaster/add", {
            title: "Attenzione!",
            text: "Errore durante il salvataggio dei dati",
            color: "info",
            autohide: true,
          });
        });
    },
  },
};
</script>
