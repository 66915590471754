<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <CAlert v-if="error !== null" color="danger" class="mb-3">
      Attenzione...
    </CAlert>

    <CRow class="mb-3">
      <CCol
        ><strong>Segnalatore: </strong
        ><span v-if="user"
          >{{ user.first_name }} {{ user.last_name }}</span
        ></CCol
      >
      <CCol
        ><strong>Data: {{ now() }}</strong></CCol
      >
    </CRow>

    <form action="" @submit.prevent="onSubmit" ref="form">
      <div class="form-group">
        <label for="message">Messaggio</label>
        <textarea
          :class="{ 'form-control': true, 'is-invalid': haveFieldError('msg') }"
          id="message"
          v-model="message"
          placeholder="descrivi il problema da segnalare..."
          rows="9"
        ></textarea>
        <div class="invalid-feedback d-block" v-if="haveFieldError('msg')">
          {{ getFieldError("msg") }}
        </div>
      </div>
    </form>

    <h5>Elementi da segnalare:</h5>
    <ul class="mb-2">
      <li v-for="item of items" :key="item.id">
        <span>{{ item.label }}</span>
        <span class="text-danger ml-2" v-if="item.error">{{ item.error }}</span>
      </li>
    </ul>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close"
        >ANNULLA</CButton
      >
      <vue-ladda
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >CONFERMA</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import { INCIDENT_ENTITY_TYPE, INCIDENT_TYPE } from "../../../../config/global";
import EventBus from "../../../../helpers/EventBus";
import { formatDate, isObject } from "../../../../helpers/common";

export default {
  name: "OrdersIncidentModal",

  data() {
    return {
      INCIDENT_TYPE,
      loading: false,
      error: null,
      message: "",
    };
  },

  watch: {
    show() {
      // reset on open/close modal
      this.reset();
    },
  },

  computed: {
    title() {
      return this.$store.state.incidents.create.type === INCIDENT_TYPE.INCIDENT
        ? "Apri segnalazione"
        : "Annulla";
    },

    show() {
      return this.$store.state.incidents.create.show;
    },

    type() {
      return this.$store.state.incidents.create.type;
    },

    lang: function () {
      return this.$i18n.locale;
    },

    user() {
      return this.$auth.user() || null;
    },

    items() {
      const items = [];
      const { entities } = this.$store.state.incidents.create;

      const ddmpHaveMarkerInfos = (entry) => {
        return get(entry, "ddmp.market.role", null) !== null;
      };

      const ddmiHaveMarkerInfos = (entry) => {
        return get(entry, "ddmi.market.role", null) !== null;
      };

      Object.keys(entities).forEach((type) => {
        const values = entities[type];

        if (values.length === 0) {
          return;
        }

        switch (type) {
          case INCIDENT_ENTITY_TYPE.DDMPS:
            items.push(
              ...values.map((entity) => {
                const producerId = get(entity, "ddmp.producer.id");
                const producerName = get(entity, "ddmp.producer.role.name");
                const marketName = get(entity, "ddmp.market.role.name");

                const label = ddmpHaveMarkerInfos(entity)
                  ? marketName
                  : `P${producerId} - ${producerName}`;

                return {
                  id: entity.id,
                  type,
                  label,
                  error: this.getEntityError(type, entity.id),
                };
              })
            );
            break;

          case INCIDENT_ENTITY_TYPE.DDMIS:
            items.push(
              ...values.map((entity) => {
                const islandId = get(entity, "ddmi.island.id");
                const islandName = get(entity, "ddmi.island.role.name");
                const marketName = get(entity, "ddmi.market.role.name");

                const label = ddmiHaveMarkerInfos(entity)
                  ? marketName
                  : `I${islandId} - ${islandName}`;

                return {
                  id: entity.id,
                  type,
                  label,
                  error: this.getEntityError(type, entity.id),
                };
              })
            );

            break;

          case INCIDENT_ENTITY_TYPE.DETAILS:
            items.push(
              ...values.map((entity) => {
                const productName = get(
                  entity,
                  `ppm.lot.productproducer.translations.${this.lang}.name`
                );
                const variantName = get(
                  entity,
                  `ppm.lot.translations.${this.lang}.name`
                );
                const familyName = get(entity, `order.family.name`);
                const orderId = get(entity, `order.id`);

                return {
                  id: entity.id,
                  type,
                  label: `${productName} - ${variantName} (${familyName} #${orderId})`,
                  error: this.getEntityError(type, entity.id),
                };
              })
            );
            break;

          case INCIDENT_ENTITY_TYPE.ODETAILS:
            items.push(
              ...values.map((entity) => {
                const productName = get(
                  entity,
                  `ppm.lot.productproducer.translations.${this.lang}.name`
                );

                const variantName = get(
                  entity,
                  `ppm.lot.translations.${this.lang}.name`
                );

                return {
                  id: entity.id,
                  type,
                  label: `${productName} - ${variantName}`,
                  error: this.getEntityError(type, entity.id),
                };
              })
            );

            break;

          case INCIDENT_ENTITY_TYPE.PDETAILS:
            items.push(
              ...values.map((entity) => {
                const productName = get(
                  entity,
                  `ppm.lot.productproducer.translations.${this.lang}.name`
                );
                const variantName = get(
                  entity,
                  `ppm.lot.translations.${this.lang}.name`
                );
                return {
                  id: entity.id,
                  type,
                  label: `${productName} - ${variantName}`,
                  error: this.getEntityError(type, entity.id),
                };
              })
            );
            break;

          case INCIDENT_ENTITY_TYPE.ORDERS:
            items.push(
              ...values.map((entity) => {
                const orderId = get(entity, "order.id");
                const familyName = get(entity, "order.family.name", "");
                return {
                  id: entity.id,
                  type,
                  label: `${orderId} - ${familyName}`,
                  error: this.getEntityError(type, entity.id),
                };
              })
            );
            break;

          case INCIDENT_ENTITY_TYPE.PLISTS:
            items.push(
              ...values.map((entity) => {
                const producerName = get(entity, "producer.role.name", "");
                return {
                  id: entity.id,
                  type,
                  label: `${producerName}`,
                  error: this.getEntityError(type, entity.id),
                };
              })
            );
            break;

          case INCIDENT_ENTITY_TYPE.PORDERS:
            items.push(
              ...values.map((entity) => {
                const orderId = get(entity, `order.order.id`);

                const familyName = `F${get(
                  entity,
                  "order.order.family.id",
                  ""
                )} ${get(entity, "order.order.family.name", "")}`;

                return {
                  id: entity.id,
                  type,
                  label: `${familyName} (#${orderId})`,
                  error: this.getEntityError(type, entity.id),
                };
              })
            );

            break;

          default:
            console.warn(`Sorry, type "${type}" not supported.`);
        }
      });
      return items;
    },
  },

  methods: {
    reset() {
      this.loading = false;
      this.error = null;
      this.message = "";
    },

    close() {
      this.$store.commit("incidents/createHide");
    },

    onSubmit() {
      const roleId = this.$store.state.role.id;
      const { message } = this;

      if (this.type === INCIDENT_TYPE.INCIDENT) {
        this.error = null;
        this.loading = true;
        this.$store
          .dispatch("incidents/createIncident", { roleId, message })
          .then((response) => {
            this.$store.commit("incidents/resetState");
            EventBus.$emit("orders:refresh");
          })
          .catch((error) => {
            this.error = error.response.data;
          })
          .finally(() => {
            this.loading = false;
          });
      }

      if (this.type === INCIDENT_TYPE.CANCELLATION) {
        if (
          confirm(
            `Sicuro di voler procedere all'annullamento degli elementi selezionati (NON sarà possibile annullare)?`
          )
        ) {
          this.error = null;
          this.loading = true;
          this.$store
            .dispatch("incidents/createCancellation", { roleId, message })
            .then(() => {
              this.$store.commit("incidents/resetState");
              EventBus.$emit("orders:refresh");
            })
            .catch((error) => {
              console.error(error);
              this.error = error.response.data;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }
    },

    getEntityError(type, entityId) {
      const error = get(this.error, `errors.fields.${type}.${entityId}`, null);
      return isObject(error) ? Object.values(error).join(", ") : null;
    },

    haveFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null) !== null;
    },

    getFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null);
    },

    now() {
      return formatDate(new Date(), "dd-MM-yyyy");
    },
  },
};
</script>
