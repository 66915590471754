<template>
  <CModal :show="show" :closeOnBackdrop="false" :centered="true">
    <template #header>
      <h5 class="modal-title">{{ title }}</h5>
    </template>

    <CAlert v-if="error !== null" color="danger" class="mb-3">
      Attenzione...
    </CAlert>

    <CRow class="mb-3">
      <CCol
        ><strong>Utente: </strong
        ><span v-if="user"
          >{{ user.first_name }} {{ user.last_name }}</span
        ></CCol
      >
      <CCol
        ><strong>Data: {{ now() }}</strong></CCol
      >
    </CRow>

    <form action="" @submit.prevent="onSubmit" ref="form">
      <div class="form-group">
        <label for="msg">Messaggio</label>
        <textarea
          :class="{ 'form-control': true, 'is-invalid': haveFieldError('msg') }"
          id="message"
          v-model="msg"
          placeholder="scrivi il messaggio..."
          rows="9"
        ></textarea>
        <div class="invalid-feedback d-block" v-if="haveFieldError('msg')">
          {{ getFieldError("msg") }}
        </div>
      </div>
    </form>

    <template #footer>
      <CButton color="primary" variant="outline" @click.prevent="close"
        >ANNULLA</CButton
      >
      <vue-ladda
        :loading="loading"
        data-style="zoom-in"
        button-class="btn btn-primary px-4"
        @click.prevent="onSubmit"
        >CONFERMA</vue-ladda
      >
    </template>
  </CModal>
</template>

<script>
import get from "lodash/get";
import EventBus from "../../../../helpers/EventBus";
import { formatDate } from "../../../../helpers/common";

export default {
  name: "OrdersIncidentMessageModal",

  data() {
    return {
      loading: false,
      error: null,
      msg: "",
    };
  },

  watch: {
    show() {
      // reset on open/close modal
      this.reset();
    },
  },

  computed: {
    title() {
      return "Aggiungi messaggio";
    },

    show() {
      return this.$store.state.incidents.message.show;
    },

    incident_id() {
      return this.$store.state.incidents.message.incident_id;
    },

    lang: function () {
      return this.$i18n.locale;
    },

    user() {
      return this.$auth.user() || null;
    },
  },

  methods: {
    reset() {
      this.loading = false;
      this.error = null;
      this.msg = "";
    },

    close() {
      this.$store.commit("incidents/messageHide");
    },

    onSubmit() {
      const incident_id = this.incident_id;
      const { msg } = this;

      this.error = null;
      this.loading = true;
      this.$store
        .dispatch("incidents/messageAdd", { incident_id, msg })
        .then((response) => {
          this.$store.commit("incidents/resetState");
          EventBus.$emit("orders:refresh");
        })
        .catch((error) => {
          this.error = error.response.data;
        })
        .finally(() => {
          this.loading = false;
        });
    },

    haveFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null) !== null;
    },

    getFieldError(field) {
      return get(this.error, `errors.fields.${field}`, null);
    },

    now() {
      return formatDate(new Date(), "dd-MM-yyyy");
    },
  },
};
</script>
